import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { AdminRoleCategory } from "../types/adminRole";

export function useRole() {
  const { t } = useTranslation("role");

  const roleRouter = useMemo(
    () => ({
      [AdminRoleCategory.ADMIN_ROOT.toString()]: t("adminRoot"),
      [AdminRoleCategory.ADMIN_MANAGER.toString()]: t("adminManager"),
      [AdminRoleCategory.ADMIN_GENERAL.toString()]: t("adminGeneral"),
      [AdminRoleCategory.SPECIAL_ADMIN.toString()]: t("specialAdmin"),
      // [AdminRoleCategory.CONSULTANT_MNG.toString()]: t("consultantManager"),
      // [AdminRoleCategory.CONSULTANT_GENERAL.toString()]: t("consultantGeneral"),
      [AdminRoleCategory.CONSULTANT_MANAGER.toString()]: t("consultantManager"),
      [AdminRoleCategory.CONSULTANT.toString()]: t("consultant"),
    }),
    [t]
  );

  const getRoleText = useCallback(
    (roleCategory: AdminRoleCategory) => roleRouter[roleCategory.toString()] ?? "",
    [roleRouter]
  );

  return {
    getRoleText,
  };
}
