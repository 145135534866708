import style from "../style/registration.module.scss";
import {Label104x46} from "../../../components/Label";
import React from "react";

interface Args{
    label: string;
    isRequired: boolean;
    placeHolder: string;
    value: string;
    setValue: (_: string) => void
}
export function CounselorLongInputField({
                                            label, isRequired, placeHolder, value, setValue
                                        }:Args) {
    /**
     * input 길이가 긴 컴포넌트
     */

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const cursorPosition = e.currentTarget.selectionStart;
            const newValue = value.slice(0, cursorPosition) + '\n' + value.slice(cursorPosition);
            setValue(newValue);
        }
    };

    return(
        <>
            <div className={style.longInputWrapper}>
                <Label104x46 text={label}
                             isRequired={isRequired}/>
                <textarea
                    className={`${style.longInput} ${style.inputText}`}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={placeHolder}
                />
            </div>
        </>
    )
}