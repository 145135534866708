import style from "../style/registration.module.scss";
import {Label104x46} from "../../../components/Label";
import React from "react";
import {ValidStatus} from "../../../types/common";
import {useErrorMessageHandler} from "./hooks/useErrorMessageHandler";
import {isOnlyNumbers} from "../../../util/etcUtil";

interface Args {
    label: string;
    placeHolder: string;
    minLength: number;
    maxLength: number;
    isRequired: boolean;
    isLabelVisible: boolean;
    additionalText?: string;
    value: string;
    setValue: (_: string) => void
    validate?: ValidStatus;
    setValidate?: (_: ValidStatus) => void
}

export function CounselorShortNumberField(
    {
        label, placeHolder,
        minLength, maxLength,
        isRequired, isLabelVisible,
        additionalText,
        value, setValue, validate, setValidate
    }: Args) {
    /**
     * 중복확인 버튼이 없는
     * height가 작은 인풋 영역
     */
    const {passwordErrorHandler} = useErrorMessageHandler();

    const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = (e.target as HTMLInputElement).value

        if (inputValue === '' || (inputValue.length <= maxLength && isOnlyNumbers(inputValue))) {
            setValue(inputValue);
            setValidate && setValidate(ValidStatus.NONE)
        }
    }

    return (
        <>
            <div className={style.mainInputWrapper}>
                {isLabelVisible ? (
                        <Label104x46 text={label} isRequired={isRequired}/>
                    )
                    : (
                        <div className={style.invisibleBox}/>
                    )}

                <div className={style.shortInputWrapper}>
                    <input className={`${style.shortInput} ${style.inputText}`}
                           placeholder={placeHolder}
                           type={"number"}
                           value={value}
                           onChange={(e) => handleInputValue(e)}
                           minLength={minLength}
                           maxLength={maxLength}
                    />
                    <span className={style.descriptionText}>
                                {additionalText}
                    </span>

                    {validate === ValidStatus.INPUT_LENGTH && (
                        <div className={style.inValidateText}>최소 {minLength}자리 이상으로 입력해 주세요.</div>
                    )}

                    {validate && passwordErrorHandler(validate)}
                </div>
            </div>
        </>
    );
}