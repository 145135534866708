import profileBar from "./profileBar.json";
import role from "./role.json";
import nav from "./nav.json";
import user from "./member/user.json";
import filter from "./filter.json";
import common from "./common.json";
import tester from "./member/tester.json";
import organManager from "./member/organManager.json";
import simpleTester from "./member/simpleTester.json";
import admin from "./member/admin.json";
import member from "./member/member.json";
import account from "./account.json";
import memo from "./memo.json";
import product from "./product.json";
import coupon from "./coupon.json";
import order from "./order.json";
import unitItem from "./unitItem.json";
import terms from "./terms.json";
import test from "./test.json";
import voucher from "./voucher.json";
import couponCode from "./couponCode.json";
import testResult from "./test_result/testResult.json";
import testResultInfo from "./test_result/testResultInfo.json";
import testResultCover from "./test_result/testResultCover.json";
import testResultHeader from "./test_result/testResultHeader.json";
import totalScale from "./test_result/totalScale.json";
import lowLevelScale from "./test_result/lowLevelScale.json";
import average from "./test_result/average.json";
import personal from "./test_result/personal.json";
import profile from "./test_result/profile.json";
import introduction from "./test_result/introduction.json";
import guideModal from "./test_result/guideModal.json";
import guidePersonalDetail from "./test_result/guidePersonalDetail.json";
import guideCommon from "./test_result/guideCommon.json";
import guideLearningStrategy from "./test_result/guideLearningStrategy.json";
import guideRecommendationJob from "./test_result/guideRecommendationJob.json";
import counselorGroup from "./counselor/counselorGroup.json";
import counselorInfo from "./counselor/counselorInfo.json";

const files = {
    account,
    memo,
    product,
    coupon,
    order,
    unitItem,
    profileBar,
    role,
    nav,
    user,
    filter,
    common,
    tester,
    organManager,
    admin,
    member,
    terms,
    test,
    voucher,
    simpleTester,
    couponCode,
    testResult,
    testResultInfo,
    testResultCover,
    testResultHeader,
    totalScale,
    lowLevelScale,
    average,
    personal,
    profile,
    introduction,
    guideModal,
    guidePersonalDetail,
    guideCommon,
    guideLearningStrategy,
    guideRecommendationJob,
    counselorGroup,
    counselorInfo,
};

export default files;
