import {useCallback} from "react";
import {
    ConsultantPasswordPutDTO,
    CounselorGroupInfoDTO,
    CounselorGroupPutDTO,
    CounselorInfoPutDTO
} from "../../../../types/counselor";
import {
    adminConsultantUnblockAsync,
    deleteCounselingAffiliationAsync,
    putGroupDetailAsync,
    updateAffiliationsConsultantManagerAsync,
    updateConsultantAsync, updateConsultantPasswordAsync
} from "../../../../store/counselorSlice";
import {useAppDispatch} from "../../../../hooks/hooks";
import {FileClassification, FileResponse} from "../../../../types/file";
import {useFile} from "../../../../hooks/file/useFile";

export function useCounselorDetailFunctions() {
    /**
     * 상담 상세의 조회를 제외한 API 처리
     */
    // 이미지 저장용
    const {uploadImages} = useFile();
    const dispatch = useAppDispatch();

    // 상담소속 정보 변경 API
    const putCounselorGroupDetail = useCallback(
        async ({idx, body}: { idx: string, body: CounselorGroupPutDTO }) => {
            try {
                const result: CounselorGroupInfoDTO = await dispatch(putGroupDetailAsync({idx, body})).unwrap();
                return result;
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
            //eslint-disable-next-line
        }, [dispatch]);

    // 상담소속 관리자 변경 API
    const updateManager = useCallback(
        async ({idx, managerIdx}: { idx: string, managerIdx: string }) => {
            try {
                return await dispatch(updateAffiliationsConsultantManagerAsync({idx, managerIdx})).unwrap();
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
            //eslint-disable-next-line
        }, [dispatch]);

    // 상담소속 관리자 변경 API
    const deleteCounselorAffiliation = useCallback(
        async (idx: string) => {
            try {
                return await dispatch(deleteCounselingAffiliationAsync(idx));
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
            //eslint-disable-next-line
        }, [dispatch]);

    // 상담사 정보 변경 API
    const putConsultantDetail = useCallback(
        async ({idx, body}: { idx: string, body: CounselorInfoPutDTO }) => {
            try {
                const files: FileResponse[] = body?.image
                    ? await uploadImages([body?.image?.file], FileClassification.ADMIN_PROFILE_IMAGE)
                    : [];

                if (files.length > 0) {
                    body.addedFile = {uri: files[0]?.uri, fileIdx: files[0].idx};
                }

                return await dispatch(updateConsultantAsync({idx, body}));
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
            //eslint-disable-next-line
        }, [dispatch]);

    // 상담사 본인 비밀번호 변경 API
    const putConsultantPassword = useCallback(
        async (body: ConsultantPasswordPutDTO) => {
            try {
                return await dispatch(updateConsultantPasswordAsync(body));
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
            //eslint-disable-next-line
        }, [dispatch]);

    // 상담사 차단 해제 API
    const putConsultantUnblock = useCallback(
        async (idx: number[]) => {
            try {
                return await dispatch(adminConsultantUnblockAsync({idx}));
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
            //eslint-disable-next-line
        }, [dispatch]);


    // 상담 소속정보 변경 함수
    const onClickAffiliationUpdateButton = useCallback((id: string, groupInfoPutParam: CounselorGroupPutDTO) => {
        if (!id || !groupInfoPutParam) return false;

        const isConfirmed = window.confirm('상담 소속 정보를 변경하시겠습니까?');
        if (isConfirmed) {
            putCounselorGroupDetail({idx: id, body: groupInfoPutParam}).then(() => {
                alert('상담 소속 정보가 수정되었습니다.')
                window.location.reload();
            })
        }
    }, [putCounselorGroupDetail]);

    // 상담 소속관리자 변경 함수
    const onClickManagerUpdateButton = useCallback(({idx, managerIdx}: { idx: string, managerIdx: string }) => {
        if (!idx || !managerIdx) return false;

        const isConfirmed = window.confirm('상담 소속관리자를 정말 변경 하시겠습니까?');
        if (isConfirmed) {
            updateManager({idx: idx, managerIdx: managerIdx}).then(() => {
                alert('상담 소속관리자가 변경 되었습니다.')
                window.location.reload();
            })
        }
    }, [updateManager]);

    // 상담 소속 삭제 함수
    const onClickAffiliationDeleteButton = useCallback((idx: string) => {
        if (!idx) return false;

        const firstMessage = '상담 소속을 정말로 삭제 하시겠습니까?\n상담 소속을 삭제할 경우 상담 소속에 등록된 모든 상담사 회원은 무소속으로 전환 됩니다.';
        const secondMessage = '상담 소속을 정말로 삭제 하시겠습니까?\n삭제한 정보는 복구할 수 없으니 신중하게 결정해 주세요.';

        const firstConfirm = window.confirm(firstMessage);
        if (!firstConfirm) return;

        const secondConfirm = window.confirm(secondMessage);
        if (!secondConfirm) return;

        if (firstConfirm) {
            if (secondConfirm) {
                deleteCounselorAffiliation(idx).then(() => {
                    alert('상담 소속이 삭제되었습니다.');
                    window.close();
                });
            }
        }
    }, [deleteCounselorAffiliation]);

    // 상담사 정보 변경 함수
    const onClickUpdateUserButton = useCallback((idx: string, groupInfoPutParam: CounselorInfoPutDTO) => {
        if (!idx || !groupInfoPutParam) return false;

        const isConfirmed = window.confirm('상담사 정보를 변경하시겠습니까?');
        if (isConfirmed) {
            putConsultantDetail({idx: idx, body: groupInfoPutParam}).then(() => {
                alert('상담 소속관리자가 변경 되었습니다.')
                window.location.reload();
            })
        }
    }, [putConsultantDetail]);

    // 상담사 본인 비밀번호 변경 함수
    const onClickUpdatePasswordButton = useCallback((body: ConsultantPasswordPutDTO) => {
        const isConfirmed = window.confirm('비밀번호를 변경하시겠습니까?');
        if (isConfirmed) {
            putConsultantPassword(body).then(() => {
                alert('비밀번호가 변경되었습니다.')
                window.close()
            })
        }
    }, [putConsultantPassword]);

    // 비밀번효 변경 버튼
    const onClickChangePassword = useCallback(() => {
        window.open('password')
        //eslint-disable-next-line
    }, []);

    // 필굿관리자 회원 차단 해제
    const onClickUnblockBtn = (id?: string) => {
        if (!id) return;
        const idx = Number(id)
        const param = [idx]

        putConsultantUnblock(param).then(() => {
            alert('차단이 해제되었습니다.')
            window.location.reload();
        });
    };
    return {
        // 상담소속
        onClickAffiliationUpdateButton, onClickManagerUpdateButton, onClickAffiliationDeleteButton,

        // 상담사
        onClickUpdateUserButton, onClickUpdatePasswordButton, onClickChangePassword, onClickUnblockBtn
    };
}