import { Trans, useTranslation } from "react-i18next";
import { StrongDarkTextStyle } from "./Texts";
import React, { useCallback, useMemo } from "react";
import { LOCALES_STRING } from "../util/etcUtil";

interface Props {
  totalCount: number;
  whichList: WhichList;
}

export enum WhichList {
  MEMBER = "MEMBER",
  TESTER = "TESTER",
  TEST_HISTORY = "TEST_HISTORY",
  PAYMENT = "PAYMENT",
  PRODUCT = "PRODUCT",
  UNIT_ITEM = "UNIT_ITEM",
  COUPON = "COUPON",
  CODE = "CODE",
  PARTNER = "PARTNER",
  POPUP = "POPUP",
  BANNER = "BANNER",
  EXTERNAL_VOUCHER = "EXTERNAL_VOUCHER",
  COUNSELOR = "COUNSELOR",
  COUNSELING = "COUNSELING"
}

export function ListCount({ totalCount, whichList }: Props) {
  const { t } = useTranslation("common");

  const unitKeyRouter = useMemo(
    () => ({
      [WhichList.MEMBER.toString()]: "memberUnit",
      [WhichList.TESTER.toString()]: "testerUnit",
      [WhichList.TEST_HISTORY.toString()]: "testHistoryUnit",
      [WhichList.PAYMENT.toString()]: "paymentUnit",
      [WhichList.PRODUCT.toString()]: "productUnit",
      [WhichList.UNIT_ITEM.toString()]: "unitItemUnit",
      [WhichList.COUPON.toString()]: "couponUnit",
      [WhichList.CODE.toString()]: "codeUnit",
      [WhichList.PARTNER.toString()]: "partnerUnit",
      [WhichList.POPUP.toString()]: "popupUnit",
      [WhichList.BANNER.toString()]: "bannerUnit",
      [WhichList.EXTERNAL_VOUCHER.toString()]: "externalVoucherUnit",
      [WhichList.COUNSELOR.toString()]: "counselorUnit",
      [WhichList.COUNSELING.toString()]: "counselingUnit",
    }),
    []
  );

  const TotalCountComponent = useCallback(
    () => <StrongDarkTextStyle>{totalCount.toLocaleString(LOCALES_STRING)}</StrongDarkTextStyle>,
    [totalCount]
  );
  const UnitComponent = useCallback(
    () => (
      <>
        {totalCount > 1
          ? t(`${unitKeyRouter[whichList.toString()]}.onlyMultipleUnit`)
          : t(`${unitKeyRouter[whichList.toString()]}.onlySingleUnit`)}
      </>
    ),
    [totalCount, t, unitKeyRouter, whichList]
  );

  const transKeyRouter = useMemo(
    () => ({
      [WhichList.MEMBER.toString()]: "member",
      [WhichList.TESTER.toString()]: "tester",
      [WhichList.TEST_HISTORY.toString()]: "testHistory",
      [WhichList.PAYMENT.toString()]: "payment",
      [WhichList.PRODUCT.toString()]: "product",
      [WhichList.UNIT_ITEM.toString()]: "unitItem",
      [WhichList.COUPON.toString()]: "coupon",
      [WhichList.CODE.toString()]: "code",
      [WhichList.PARTNER.toString()]: "partner",
      [WhichList.POPUP.toString()]: "popup",
      [WhichList.BANNER.toString()]: "banner",
      [WhichList.EXTERNAL_VOUCHER.toString()]: "externalVoucher",
      [WhichList.COUNSELOR.toString()]: "counselor",
      [WhichList.COUNSELING.toString()]: "counseling",
    }),
    []
  );

  return (
    <Trans
      t={t}
      i18nKey={`listTotalCount.${transKeyRouter[whichList.toString()]}`}
      components={[<TotalCountComponent />, <UnitComponent />]}
    />
  );
}
