import styled from "styled-components";
import variables from "../assets/scss/variable.module.scss";
import { Selector } from "../layout/content/selector/Selector";
import { Textarea } from "./Textarea";
import { SELECTED_VALUE, SELECTOR_AREA } from "../util/etcUtil";

export const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 14px;

  color: ${variables.color75};

  &::placeholder {
    color: ${variables.colorC6};
  }
`;

export const GridInput = styled(Input)`
  border: none;
  outline: none;
  padding: 12px 20px;
`;

export const GridInputText = styled(GridInput).attrs({ type: "input" })``;

export const GridTextArea = styled(GridInput).attrs({ as: "textarea" })``;

export const GridInputNumber = styled(GridInput).attrs({ type: "number" })``;
export const GridInputTel = styled(GridInput).attrs({ type: "tel" })``;

export const InputSelector = styled(Selector)`
  border: none;
  width: 100%;
  height: 100%;
  background-position: right 10px center;
  flex-shrink: 0;

  [data-name=${SELECTOR_AREA}] {
    padding: 12px 10px 12px 20px !important;
    border: none;
  }

  [data-name=${SELECTED_VALUE}] {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  ul,
  ul > li {
    width: ${(props: { optionWidth?: string }) => props.optionWidth ?? "100%"} !important;
  }
`;

export const FilterInput = styled.input`
  border: 1px solid ${variables.colorDE};
  color: ${variables.color75};
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: ${variables.colorC6};
  }
`;

export const FilterInputCalendar = styled(FilterInput).attrs({ type: "date" })`
  width: 154px;
  height: 46px;
  padding: 12px 20px;
`;

export const FilterInputNumber = styled(FilterInput).attrs({ type: "text" })`
  width: 80px;
  height: 46px;
  padding: 12px 0;
  text-align: center;

  &.notKO {
    width: 100px;
  }
`;

export const FilterInputText = styled(FilterInput).attrs({ type: "text" })`
  width: 360px;
  height: 46px;
  padding: 12px 14px;
`;

export const InputPasswordStyle = styled(FilterInput).attrs({ type: "password" })`
  width: 360px;
  height: 46px;
  padding: 12px 14px;
`;

export const InputLineGroupFormStyle = styled.form`
  display: flex;
  gap: 30px;
  flex-direction: column;
  margin-top: 20px;
`;

export const InputLineStyle = styled.div`
  display: flex;
  gap: 20px;

  .form-input {
    flex-grow: 1;
  }

  > .refund-radio-group {
    flex-grow: 1;
    flex-flow: wrap;
    width: 0;
    gap: 15px 20px;
  }
`;

export const InputWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .description {
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.03em;
    color: ${(props) => props.theme.color.c75};
  }
`;

export const InputTextStyle = styled.input.attrs({ type: "text", className: "form-input" })`
  border: 1px solid ${(props) => props.theme.color.cDE};
  color: ${(props) => props.theme.color.c75};
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.03em;
  height: 46px;
  line-height: 46px;
  padding: 0 20px;

  &::placeholder {
    color: ${(props) => props.theme.color.cC6};
  }
`;

export const InputTextAreaStyle = styled(Textarea).attrs({ className: "form-input" })``;
