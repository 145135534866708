import {useCallback, useMemo, useState} from "react";
import {
    ConsultantDetailDTO,
    ConsultantDTO,
    CounselorGroupInfoDTO,
    CounselorPosition
} from "../../../../types/counselor";
import {
    getAffiliationConsultantsAsync,
    getConsultantDetailAsync,
    getGroupDetailAsync
} from "../../../../store/counselorSlice";
import {useAppDispatch} from "../../../../hooks/hooks";
import {useCounselorDefaultValues} from "./useCounselorDefaultValues";
import {ordersToSortArr, PageResponse, PageSearchCondition, SearchCondition} from "../../../../types/page";
import {callAsync} from "../../../../util/sliceUtil";
import {useContentDetailHeaderInfoSetter} from "../../../../layout/content/hooks/useContentDetailHeaderInfoSetter";

export function useCounselorDetailGetter(id:string) {
    /**
     * 상담 상세의 조회 API 처리
     */
    const dispatch = useAppDispatch();
    const {COUNSELOR_GROUP_DETAIL, COUNSELOR_DETAIL} = useCounselorDefaultValues();
    const [affiliationConsultants,setAffiliationConsultants] = useState<PageResponse<ConsultantDTO>>()
    const [consultantDetail,setConsultantDetail] = useState<ConsultantDetailDTO>(COUNSELOR_DETAIL)
    const [groupDetail, setGroupDetail] = useState<CounselorGroupInfoDTO>(COUNSELOR_GROUP_DETAIL)
    const { setCategory, setName } = useContentDetailHeaderInfoSetter();

    // 상담소속 상세 조회
    const getCounselorGroupDetail = useCallback(
        async () => {
            try {
                const result: CounselorGroupInfoDTO = await dispatch(getGroupDetailAsync(id)).unwrap();
                setGroupDetail(result)
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );

    const pageCondition = useMemo(
        () => ({
            page: affiliationConsultants?.number ?? 0,
            size: affiliationConsultants?.size ?? 5,
            sort: ordersToSortArr(affiliationConsultants?.orders),
        }),
        [affiliationConsultants]);

    const getAffiliationProfile =
        ({page, sort, size}: PageSearchCondition) =>
            () => {
                const param: SearchCondition = {
                    pageCondition: {
                        page: page ?? 0,
                        size: size ?? pageCondition.size,
                        sort: sort ?? pageCondition.sort,
                    },
                };

                callAsync(
                    dispatch(getAffiliationConsultantsAsync({
                        idx:id,condition:param
                    })).unwrap(),
                    (result: PageResponse<ConsultantDTO>) => {
                        setAffiliationConsultants(result)
                    },
                    () => alert('일시적인 문제가 발생했습니다.\n잠시 후 다시 시도해주세요.')
                ).then();
            };

    // 상담소속에 속한 상담사 조회
    const getAffiliationConsultants = useCallback(
        async () => {
            try {
                const result: PageResponse<ConsultantDTO> = await dispatch(
                    getAffiliationConsultantsAsync({
                        idx:id,condition: {pageCondition}
                    })).unwrap();
                setAffiliationConsultants(result)
                return result;
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );

    // 상담사 상세 조회
    const getConsultantDetail = useCallback(
        async () => {
            try {
                const result: ConsultantDetailDTO = await dispatch(getConsultantDetailAsync(id)).unwrap();
                setConsultantDetail(result);

                setCategory(result.consultantRank === CounselorPosition.CONSULTANT_MANAGER
                    ? "상담관리자"
                    : "상담사")
                setName(result.name)
            } catch (e) {
                console.error(e);
                alert('일시적인 문제가 발생했습니다. \n다시 시도해주세요.');
            }
        },
        //eslint-disable-next-line
        [dispatch]
    );


    return{
        getCounselorGroupDetail, groupDetail,
        getAffiliationConsultants, affiliationConsultants, getAffiliationProfile,
        getConsultantDetail, consultantDetail,
    }
}